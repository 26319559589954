import { mdHttp, otherHttp, sysHttp } from '/@/utils/http/axios';
import {
  LoginParams,
  LoginResultModel,
  GetUserInfoModel,
  UserMenuTreeResultModel,
  GetMyPermissionParams,
  GetMyPermissionResultModel,
  SwitchBzParams,
} from './model/userModel';

import { ErrorMessageMode } from '/#/axios';
import { Encrypt } from '/@/utils/crypto';
import { useUserStore } from '/@/store/modules/user';

export enum UserApi {
  Login = '/oauth/token',
  Logout = '/token/',
  GetUserInfo = '/user/info',
  ModifyPwd = '/user/modifyPwd',
  UserMenuTree = '/user/menuTree/5',
  GetMyPermission = '/kefuUser/getMyPermission/',
  SwitchBz = '/user/switchBz',
}

/**
 * @description: user login api
 */
export function loginApi(data: LoginParams, mode: ErrorMessageMode = 'modal') {
  // 用户名加密
  data.username = Encrypt(`fk,${data.username},platform`);
  // 密码加密
  data.password = Encrypt(data.password);
  return otherHttp.get<LoginResultModel>(
    {
      url: UserApi.Login,
      params: data,
    },
    {
      errorMessageMode: mode,
    },
  );
}

// 选择事业部
export function switchBz(params: SwitchBzParams) {
  params.client_info = Encrypt(`{${params.client_info}}`);
  return sysHttp.get<LoginResultModel>({
    url: UserApi.SwitchBz,
    params,
  });
}

/**
 * @description: getUserInfo
 */
export async function getUserInfo() {
  const userInfo = await sysHttp.get<GetUserInfoModel>({ url: UserApi.GetUserInfo });
  return userInfo;
}

export async function doLogout() {
  const token = useUserStore().getToken;
  const res = await sysHttp.delete({
    url: UserApi.Logout + token,
    params: { token },
  });
  return res;
}

export function modifyPwd(data) {
  // 密码加密
  data.oldPwd = Encrypt(data.oldPwd);
  // 密码加密
  data.newPwd = Encrypt(data.newPwd);
  return sysHttp.post({
    url: UserApi.ModifyPwd,
    data,
  });
}

// 获取当前用户菜单(根据系统id),
export function userMenuTree() {
  return sysHttp.get<UserMenuTreeResultModel[]>({
    url: UserApi.UserMenuTree,
  });
}

// 8.获取当前用户的数据权限,
export function getMyPermission(params: GetMyPermissionParams) {
  return mdHttp.get<GetMyPermissionResultModel>({
    url: UserApi.GetMyPermission + params.userId,
  });
}
