import { useLoading } from '/@/components/Loading';

export const useRequestLoading = () => {
  const [open, close] = useLoading({
    tip: '加载中...',
  });
  let size = 0;
  const _open = () => {
    if (size === 0) {
      open();
    }
    size++;
  };
  const _close = () => {
    size--;
    if (size === 0) {
      close();
    }
  };
  return [_open, _close];
};
