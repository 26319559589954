import { isArray, isEmpty, isObject, isString } from 'lodash';
import md5 from 'md5';
import { Base64 } from 'js-base64';
import { Decrypt } from './crypto';
import { clearEmpty } from '.';
import { getSecret } from './auth';
type ObjectType = { [key: string]: any };

/**
 * 排序对象
 * @param srcObj
 * @param result
 * @returns
 */
const sortObjectToString = (srcObj: ObjectType | ObjectType[], result = ''): string => {
  if (isEmpty(srcObj)) {
    return result;
  }
  let _result = '';
  if (isArray(srcObj)) {
    srcObj.map((o, i) => {
      _result += sortObjectToString(Object.keys(o).length > 0 && !isString(o) ? o : { [i]: o });
    });
  } else if (isObject(srcObj)) {
    Object.keys(srcObj)
      .sort()
      .map((k) => {
        const obj = (srcObj as ObjectType)[k];
        if (isObject(obj) || isArray(obj)) {
          _result = sortObjectToString(obj, _result + k);
        } else {
          _result += k + obj;
        }
      });
  } else {
    _result += srcObj + '';
  }
  return result + _result;
};

function encodeRFC3986URIComponent(str) {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
  );
}

const Sign = (body?: any): string => {
  const secret_key = getSecret() as string;
  if (!secret_key) return '';
  const { sign_key } = Decrypt(secret_key) as {
    sign_key: string;
  };
  const signStr = sign_key + sortObjectToString(clearEmpty(body)).replace(/ /g, '') + sign_key;
  return md5(Base64.encode(encodeRFC3986URIComponent(signStr)));
};

export { sortObjectToString };

export default Sign;
