/*
 * @Author: jeardwang jeardwang@sina.com
 * @Date: 2023-09-04 16:56:02
 * @LastEditors: jeardwang jeardwang@sina.com
 * @LastEditTime: 2023-09-09 15:18:42
 * @FilePath: /qx-phone-lib/lib/message.ts
 * @Description: 消息
 */
import { Message } from './core';

// generateUUID 生成uuid
export const generateUUID = (): string => {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// createMessage 创建消息
export const createMessage = (): Message => {
  const message: Message = {
    messageId: generateUUID(),
    timestamp: new Date().getTime(),
    type: 'phone',
  } as Message;
  return message;
};
