import { mdHttp } from '/@/utils/http/axios';
export type FetchDictMapRequestType = {
  codes?: string; //多个字典code拼接字符串
};
export type DictType = {
  parentId?: number; // 父ID
  code?: string; // 类型编号
  name?: string; // 类型名称
  sort?: number; // 排序
  remarks?: string; // 备注
  children?: DictType[]; // 下阶类型
  id?: number; // 实体的唯一ID
  delFlag?: number; // 删除标记
};
export type FetchDictMapResponseItemsType = {
  type?: DictType; // 类型
  name?: string; // 项目名
  alias?: string; // 别名
  value: string | number; // 项目值
  value1?: string; // 其他值
  value2?: string; // 其他值
  value3?: string; // 其他值
  intiValue?: string | number;
  sort?: number; // 排序
  remarks?: string; // 备注
  children?: FetchDictMapResponseItemsType[]; // 下阶类型
};
export type FetchDictMapResponseType = {
  [key: string]: FetchDictMapResponseItemsType[];
};
/**
 * 获取字典并封装map
 * @param params
 * @param cb
 * @returns
 */
export const FetchDictMap = <T>(params: FetchDictMapRequestType = { codes: '' }) => {
  return mdHttp.get<FetchDictMapResponseType | T>(
    {
      url: '/dict/getDictMap',
      params,
    },
    {
      hideLoading: true,
    },
  );
};
