import type { AppRouteRecordRaw, RouterMap } from '/@/router/types';

import { PAGE_NOT_FOUND_ROUTE, REDIRECT_ROUTE } from '/@/router/routes/basic';

import { mainOutRoutes } from './mainOut';
import { PageEnum } from '/@/enums/pageEnum';
import { t } from '/@/hooks/web/useI18n';
import { LAYOUT } from '../constant';

// import.meta.globEager() 直接引入所有的模块 Vite 独有的功能
const modules = import.meta.globEager('./modules/**/*.ts');

// 加入到路由集合中
const routeList: any[] = [];
Object.keys(modules).forEach((key) => {
  const mod: RouterMap = modules[key].routeMap || new Map();
  routeList.push(...mod);
});
export const routesMap: RouterMap = new Map(routeList);

// 根路由
export const RootRoute: AppRouteRecordRaw = {
  path: '/',
  name: 'Root',
  redirect: PageEnum.BASE_HOME,
  meta: {
    title: 'Root',
  },
};

export const LoginRoute: AppRouteRecordRaw = {
  path: PageEnum.BASE_LOGIN,
  name: 'Login',
  component: () => import('/@/views/sys/login/index.vue'),
  meta: {
    title: t('routes.basic.login'),
  },
};

export const ExportTaskRoute: AppRouteRecordRaw = {
  path: '/outside',
  name: 'ExportTaskLayout',
  component: LAYOUT,
  meta: {
    title: '',
    hideBreadcrumb: true,
    hideMenu: true,
  },
  children: [
    {
      path: 'exportTask',
      name: 'ExportTask',
      component: () => import('/@/views/outside/exportTask/index.vue'),
      meta: {
        title: '报表下载',
      },
    },
  ],
};

// Basic routing without permission
// 未经许可的基本路由
export const basicRoutes = [
  LoginRoute,
  RootRoute,
  ...mainOutRoutes,
  REDIRECT_ROUTE,
  PAGE_NOT_FOUND_ROUTE,
];
