<template>
  <slot></slot>
</template>
<script setup lang="ts">
  import { provide, reactive, onMounted, watch, WatchStopHandle } from 'vue';
  import { FormActionType } from '/@/components/Form';
  import { NamePath } from 'ant-design-vue/es/form/interface';

  const props = defineProps<{
    data: {};
    root?: boolean; // 不收集数据
  }>();

  const list: Array<FormActionType> = reactive([]);
  provide('register', (useFormReturn: FormActionType) => {
    list.push({ ...useFormReturn });
  });
  // 表单发生变化，数据更新
  provide('change', (_data, pathKey?: string) => {
    if (!props.root) {
      stopWatch();
      const data = pathKey ? props.data[pathKey] : props.data;
      Object.assign(data || props.data, _data);
      startWatch();
    }
  });
  // 数据发生变化，表单更新
  const formDataChange = () => {
    list.forEach((item) => {
      item.setFieldsValue && item.setFieldsValue(props.data);
    });
  };
  let stopWatch: WatchStopHandle;
  const startWatch = () => {
    stopWatch = watch(() => props.data, formDataChange, { deep: true });
  };
  // 验证表单
  const validate = () => {
    return new Promise((resolve, reject) => {
      Promise.all(list.map((item) => item.validate()))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const validateFields = (nameList?: NamePath[]) => {
    return new Promise((resolve, reject) => {
      Promise.all(list.map((item) => item.validateFields(nameList)))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const submit = () => {
    return new Promise((resolve, reject) => {
      Promise.all(list.map((item) => item.submit()))
        .then((res) => {
          resolve(Object.assign({}, ...res));
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 重置表单
  const resetFields = () => {
    return new Promise((resolve, reject) => {
      Promise.all(list.map((item) => item.resetFields()))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  // 清空校验
  const clearValidate = () => {
    list.forEach((item) => item.clearValidate());
  };
  // 注册，返回表单方法
  const emit = defineEmits(['register']);
  onMounted(() => {
    emit('register', { validate, resetFields, clearValidate, validateFields, submit });
    if (!props.root) {
      formDataChange();
      startWatch();
    }
  });
</script>
<style lang="less" scoped></style>
