<template>
  <ConfigProvider :locale="getAntdLocale" :getPopupContainer="getPopupContainer">
    <AppProvider>
      <FormBox root :data="{}">
        <RouterView />
      </FormBox>
    </AppProvider>
  </ConfigProvider>
</template>

<script lang="ts" setup>
  import { ConfigProvider } from 'ant-design-vue';
  import { AppProvider } from '/@/components/Application';
  import { useTitle } from '/@/hooks/web/useTitle';
  import { useLocale } from '/@/locales/useLocale';
  import FormBox from '/@/myComponents/FormBox/index.vue';
  import { watch } from 'vue';

  import 'dayjs/locale/zh-cn';
  import { useRoute } from 'vue-router';
  import { usePermissionStore } from '/@/store/modules/permission';
  // support Multi-language
  const { getAntdLocale } = useLocale();

  // Listening to page changes and dynamically changing site titles
  useTitle();

  const getPopupContainer = (triggerNode: HTMLElement) => {
    const table = triggerNode?.closest('.kkl-basic-table');
    if (table) {
      return table as HTMLElement;
    }
    return document.body;
  };

  // 监听路由变化设置当前权限
  const route = useRoute();
  const permissionStore = usePermissionStore();
  watch(
    () => route.meta.permissions,
    (codeList) => {
      permissionStore.setRoutePermCodeList((codeList as string[]) || []);
    },
    { immediate: true },
  );
</script>
