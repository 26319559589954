import { RouterMap } from '../../types';

export const routeMap: RouterMap = new Map();

routeMap.set('/callCenter/callLog', {
  name: 'CallLog',
  meta: {
    nav: [
      { id: '40', title: '全部' },
      { id: '20', title: '呼出' },
      { id: '10', title: '呼入' },
      { id: '30', title: '未接来电' },
    ],
  },
});

routeMap.set('/callCenter/callReport', {
  name: 'CallReport',
  meta: {
    nav: [
      { id: '10', title: '通话记录' },
      { id: '20', title: '短信记录' },
    ],
  },
});

routeMap.set('/callCenter/kefuReport', {
  name: 'KefuReport',
});
