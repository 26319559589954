/*
 * @Author: jeardwang jeardwang@sina.com
 * @Date: 2023-09-04 16:24:34
 * @LastEditors: jeardwang jeardwang@sina.com
 * @LastEditTime: 2023-09-11 16:32:53
 * @FilePath: /qx-phone-lib/lib/extension.ts
 * @Description: 分机
 */
import { Call, ExtensionInfo, Message, Result } from './core';
import { createMessage } from './message';
import { WebSocketClient } from './websocket';
export class Extension {
  private websocket: WebSocketClient;
  private url: string | undefined;
  private info: ExtensionInfo & {
    register?: boolean;
    call?: any;
  };
  private _onIncomingCall: (call: Call) => void;
  private _onOutgoingCall: (call: Call) => void;
  private _onAnswerCall: (call: Call) => void;
  private _onHangupCall: (call: Call) => void;
  private _onRejectCall: (call: Call) => void;
  private _onCallRinging: (call: Call) => void;
  private _onConnected: (message: Message) => void;
  private _onDisconnected: (message: Message) => void;
  private _onReconnecting: (message: Message) => void;
  private _onRegistered: (message: Message) => void;
  private _onUnregistered: () => void;
  private _onRegistrationFailed: () => void;
  constructor(extension: ExtensionInfo, ws: WebSocketClient) {
    this.websocket = ws;
    this.info = extension;
    this._onIncomingCall = (call: Call) => {
      console.log('incomeCall:', call);
    };
    this._onOutgoingCall = (call: Call) => {
      console.log('outgoingCall:', call);
    };
    this._onAnswerCall = (call: Call) => {
      console.log('answer:', call);
    };
    this._onHangupCall = (call: Call) => {
      console.log('hangup:', call);
    };
    this._onRejectCall = (call: Call) => {
      console.log('reject:', call);
    };
    this._onCallRinging = (call: Call) => {
      console.log('ringing:', call);
    };
    this._onConnected = (message: Message) => {
      console.log('connected:', message);
    };
    this._onDisconnected = (message: Message) => {
      console.log('disconnected:', message);
    };
    this._onReconnecting = (message: Message) => {
      console.log('reconnecting:', message);
    };
    this._onRegistered = (message: Message) => {
      console.log('registered:', message);
    };
    this._onUnregistered = () => {
      console.log('unregistered');
    };
    this._onRegistrationFailed = () => {
      console.log('registrationFailed');
    };
    // this.listen();
  }

  public disconnect(): void {}

  public login(): void {}

  public listen(): void {
    this.websocket.connect(this.url).then(() => {
      this.websocket.receive((message: any) => {
        console.log('receive data:', message);
        switch (message.event) {
          // connect 连接成功后，登陆分机，然后再注册分机
          case 'connect':
            const loginData: Message = {
              ...createMessage(),
              event: 'register',
              payload: {
                appId: this.info.realm,
                userId: this.info.userName,
              },
            };
            this.websocket.sendAsync(loginData);
            break;
          case 'login':
            this.registry();
            break;
          case 'incomingCall':
            this._onIncomingCall(message.payload);
            break;
          case 'outgoingCall':
            this._onOutgoingCall(message.payload);
            break;
          case 'answerCall':
            this._onAnswerCall(message.payload);
            break;
          case 'hangupCall':
            this._onHangupCall(message.payload);
            break;
          case 'rejectCall':
            this._onRejectCall(message.payload);
            break;
          case 'callRinging':
            this._onCallRinging(message.payload);
            break;
          case 'connected':
            this._onConnected(message);
            break;
          case 'disconnect':
            // TODO:重新连接
            this._onDisconnected(message);
            break;
          case 'reconnecting':
            this._onReconnecting(message);
            break;
          case 'registered':
            this._onRegistered(message);
            break;
          case 'unregistered':
            this._onUnregistered();
            break;
          case 'registrationFailed':
            this._onRegistrationFailed();
            break;
        }
      });
    });
  }

  public async add(): Promise<Result<Message | null>> {
    const extensionData: Message = {
      ...createMessage(),
      event: 'addExtension',
      payload: {
        realm: this.info.realm,
        username: this.info.userName,
        password: this.info.password,
        displayName: this.info.displayName,
      },
    };
    return this.websocket.sendAsync(extensionData);
  }

  public async registry(): Promise<Result<Message | null>> {
    const registerData: Message = {
      ...createMessage(),
      event: 'registry',
      payload: this.info.userName,
    };
    return this.websocket.sendAsync(registerData);
  }

  public async unregistry(): Promise<Result<Message | null>> {
    const unregisterData: Message = {
      ...createMessage(),
      event: 'unregistry',
      payload: this.info.userName,
    };
    return this.websocket.sendAsync(unregisterData);
  }

  // hangup 挂断
  public async hangup(callId: string): Promise<Result<Message | null>> {
    if (callId === '') {
      return {
        success: false,
        message: 'callId不能为空',
      } as Result<null>;
    }

    const hangupData = {
      ...createMessage(),
      event: 'hangup',
      payload: {
        callId,
        extension: this.info.userName,
      },
    };
    return this.websocket.sendAsync(hangupData);
  }

  // answer 接听
  public async answer(callId: string): Promise<Result<Message | null>> {
    const answerData = {
      ...createMessage(),
      event: 'answer',
      payload: {
        callId: callId,
        extension: this.info.userName,
      },
    };
    return this.websocket.sendAsync(answerData);
  }

  // call 拨号
  public async dial(calleeNumber: string): Promise<Result<Message | null>> {
    if (calleeNumber === '') {
      return {
        success: false,
        message: '被叫号码不能为空',
      } as Result<null>;
    }
    const dialData = {
      ...createMessage(),
      event: 'dial',
      payload: {
        extension: this.info.userName,
        calleeNumber,
      },
    };
    return this.websocket.sendAsync(dialData);
  }

  // transfer 转接
  public async transfer(callId: string, extension: string): Promise<Result<Message | null>> {
    if (callId === '') {
      return {
        success: false,
        message: 'callId不能为空',
      } as Result<null>;
    }
    if (extension === '') {
      return {
        success: false,
        message: '分机号码不能为空',
      } as Result<null>;
    }
    const transferData = {
      ...createMessage(),
      event: 'transfer',
      payload: {
        callId,
        extension: this.info.userName,
        triggerExtension: extension,
      },
    };
    return this.websocket.sendAsync(transferData);
  }

  // dtmf 发送dtmf
  public async dtmf(callId: string, dtmf: string): Promise<Result<Message | null>> {
    if (callId === '') {
      return {
        success: false,
        message: 'callId不能为空',
      } as Result<null>;
    }
    if (dtmf === '') {
      return {
        success: false,
        message: 'dtmf不能为空',
      } as Result<null>;
    }
    const dtmfData = {
      ...createMessage(),
      event: 'dtmf',
      payload: {
        callId,
        dtmf,
        extension: this.info.userName,
      },
    };
    return this.websocket.sendAsync(dtmfData);
  }

  // mute 静音
  public async mute(callId: string): Promise<Result<Message | null>> {
    if (callId === '') {
      return {
        success: false,
        message: 'callId不能为空',
      } as Result<null>;
    }
    const muteData = {
      ...createMessage(),
      event: 'mute',
      payload: this.info.userName,
    };
    return this.websocket.sendAsync(muteData);
  }

  // unmute 取消静音
  public async unmute(callId: string): Promise<Result<Message | null>> {
    if (callId === '') {
      return {
        success: false,
        message: 'callId不能为空',
      } as Result<null>;
    }
    const unmuteData = {
      ...createMessage(),
      event: 'unmute',
      payload: this.info.userName,
    };
    return this.websocket.sendAsync(unmuteData);
  }

  // hold 保持
  public async hold(callId: string): Promise<Result<Message | null>> {
    if (callId === '') {
      return {
        success: false,
        message: 'callId不能为空',
      } as Result<null>;
    }
    const holdData = {
      ...createMessage(),
      event: 'hold',
      payload: {
        callId: callId,
        extension: this.info.userName,
      },
    };
    return this.websocket.sendAsync(holdData);
  }

  // unhold 取消保持
  public async unhold(callId: string): Promise<Result<Message | null>> {
    if (callId === '') {
      return {
        success: false,
        message: 'callId不能为空',
      } as Result<null>;
    }
    const unholdData = {
      ...createMessage(),
      event: 'unhold',
      payload: {
        callId: callId,
        extension: this.info.userName,
      },
    };
    return this.websocket.sendAsync(unholdData);
  }

  // IncomingCall 来电
  public onIncomingCall(callback: (call: Call) => void): void {
    this._onIncomingCall = callback;
  }

  // OutgoingCall 去电
  public onOutgoingCall(callback: (call: Call) => void): void {
    this._onOutgoingCall = callback;
  }

  // AnswerCall 接听
  public onAnswerCall(callback: (call: Call) => void): void {
    this._onAnswerCall = callback;
  }

  // HangupCall 挂断
  public onHangupCall(callback: (call: Call) => void): void {
    this._onHangupCall = callback;
  }

  // RejectCall 拒接
  public onRejectCall(callback: (call: Call) => void): void {
    this._onRejectCall = callback;
  }

  // Ringing 响铃
  public onRinging(callback: (call: Call) => void): void {
    this._onCallRinging = callback;
  }

  //  onConnected 连接成功
  public onConnected(callback: (message: Message) => void): void {
    this._onConnected = callback;
  }

  // onDisconnected 连接断开
  public onDisconnected(callback: (message: Message) => void): void {
    this._onDisconnected = callback;
  }

  // onReconnecting 重连中
  public onReconnecting(callback: (message: Message) => void): void {
    this._onReconnecting = callback;
  }

  // onRegistered 注册成功
  public onRegistered(callback: (message: Message) => void): void {
    this._onRegistered = callback;
  }

  // onUnregistered 注册失败
  public onUnregistered(callback: () => void): void {
    this._onUnregistered = callback;
  }

  // onRegistrationFailed 注册失败
  public onRegistrationFailed(callback: () => void): void {
    this._onRegistrationFailed = callback;
  }
}
