/*
 * @Author: jeardwang jeardwang@sina.com
 * @Date: 2023-09-09 11:15:28
 * @LastEditors: jeardwang jeardwang@sina.com
 * @LastEditTime: 2023-09-09 11:31:03
 * @FilePath: /qx-phone/lib/emit.ts
 * @Description: 事件发布订阅
 */
import { has, isArray, isEmpty } from 'lodash';
const Events: { [key: string]: ((data: any) => void)[] } = {};

/**
 * 发布
 * @param eventName 事件名称
 * @param data 数据
 */
export const Emit = (eventName: string, data?: any) => {
  if (has(Events, eventName) && isArray(Events[eventName]) && !isEmpty(Events[eventName])) {
    Events[eventName].map((fn) => {
      fn(data);
    });
  }
};

/**
 * 订阅
 * @param eventName 事件名称
 * @param callback 回调方法
 * @returns
 */
export const Subscribe = <T>(eventName: string, callback: (data: T) => void): number => {
  let index = 0;
  if (has(Events, eventName)) {
    Events[eventName].push(callback);
    index = Events[eventName].length;
  } else {
    Events[eventName] = [callback];
  }
  return index;
};

/**
 * 取消订阅
 * @param eventName 事件名称
 * @param id 订阅ID
 */
export const Unsubscribe = (eventName: string, id: number) => {
  if (has(Events, eventName) && isArray(Events[eventName])) {
    Events[eventName].splice(id, 1);
  }
};
