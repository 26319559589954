import { isArray, keyBy } from 'lodash';
import {
  FetchDictMap,
  FetchDictMapResponseItemsType,
  FetchDictMapResponseType,
} from '../api/sys/dict';
import { reactive, ref, watch } from 'vue';
import { LocalDict } from './localDict';
import { isReactive, isRef } from '@vue/runtime-core';
import { cloneDeep } from 'lodash-es';

let dictionary: FetchDictMapResponseType = {};
const fetchDictionaryByCode = (code: string) => {
  return dictionary[code];
};
export const fetchDictionary = async () => {
  const requestParams = {};
  // @ts-ignore
  // 本地字典
  const response: FetchDictMapResponseType = LocalDict;
  const res: FetchDictMapResponseType = await FetchDictMap(requestParams);
  Object.assign(response, res || {});
  dictionary = response;
  return response;
};

export const dictToObj = (code: string) => {
  const res: { [key: FetchDictMapResponseItemsType['value']]: FetchDictMapResponseItemsType } =
    reactive({});
  const dictionary = fetchDictionaryByCode(code);
  if (!!dictionary && isArray(dictionary)) {
    Object.assign(res, keyBy(dictionary, 'value'));
  }
  return res;
};

export const optionsToMap = (
  dictionary: { value: number | string; name?: string }[],
  _res?: Map<string | number, FetchDictMapResponseItemsType['name']>,
) => {
  const res: Map<string | number, FetchDictMapResponseItemsType['name']> =
    _res || reactive(new Map());
  for (const i in dictionary) {
    const item = dictionary[i];
    // 兼容字符串和数字
    res.set(item.value.toString(), item.name);
    res.set(Number(item.value), item.name);
  }
  if (isReactive(dictionary) || isRef(dictionary)) {
    watch(
      dictionary,
      () => {
        optionsToMap(dictionary, res);
      },
      { deep: true },
    );
  }
  return res;
};
export const dictToMap = (code: string) => {
  const res: Map<string | number, FetchDictMapResponseItemsType['name']> = reactive(new Map());
  const dictionary = fetchDictionaryByCode(code);
  if (!!dictionary && isArray(dictionary)) {
    optionsToMap(dictionary, res);
  }
  return res;
};

export const dictToValue = (code: string, name: string) => {
  const res = ref<string | number>('');
  const dictionary = fetchDictionaryByCode(code);
  if (!!dictionary && isArray(dictionary)) {
    for (const i in dictionary) {
      const item = dictionary[i];
      if (item.name == name) {
        res.value = item.intiValue ?? item.value;
      }
    }
  }
  return res;
};

export const dictToName = (code: string, value: string | number) => {
  const res = ref('');
  const dictionary = fetchDictionaryByCode(code);
  if (!!dictionary && isArray(dictionary)) {
    for (const i in dictionary) {
      const item = dictionary[i];
      if (item.intiValue == value || item.value == value) {
        res.value = item.name as string;
      }
    }
  }
  return res;
};

export function dictToOption<K extends string, V extends string>(
  code: string,
  labelKey: K,
  valueKey: V,
  exclude?: Array<string | number>, // 排除不要的值
  _res?: Array<Record<K | V, any>>,
): Array<
  Record<K, string> &
    Record<V, string | number> &
    FetchDictMapResponseItemsType & { disabled?: boolean }
>;
export function dictToOption(
  code: string,
  labelKey?: 'label',
  valueKey?: 'value',
  exclude?: Array<string | number>, // 排除不要的值
  _res?: Array<Record<'label' | 'value', any>>,
): Array<
  Record<'label', string> &
    Record<'value', string | number> &
    FetchDictMapResponseItemsType & { disabled?: boolean }
>;
export function dictToOption(
  code: string,
  labelKey = 'label',
  valueKey = 'value',
  exclude?: Array<string | number>, // 排除不要的值
): Array<
  Record<'label', string> &
    Record<'value', string | number> &
    FetchDictMapResponseItemsType & { disabled?: boolean }
> {
  const res: Array<Record<'label' | 'value', any>> = reactive([]);
  const dictionary = fetchDictionaryByCode(code);
  if (!!dictionary && isArray(dictionary)) {
    let r = dictionary
      .sort((a, b) => {
        if (a.sort) {
          return a.sort! > b.sort! ? 1 : -1;
        }
        // 不交换位置
        return 0;
      })
      .map((item) => ({
        ...item,
        [valueKey]: item['intiValue'] ?? item['value'],
        [labelKey]: item['name'] as string,
      }));
    if (exclude?.length) {
      r = r.filter((item) => !exclude!.includes(item[valueKey]));
    }
    res.push(...(cloneDeep(r) as any));
  }
  return res;
}
