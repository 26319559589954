import { defineStore } from 'pinia';
import { store } from '/@/store';
import { Phone } from '/@/utils/lib/phone';
import dayjs from 'dayjs';
import { useMessage } from '/@/hooks/web/useMessage';
import { call, getCallAgent, popOnScreen, queueLogin, unregister } from '/@/api/sd/call';
import {
  DailCallParams,
  GetCallAgentResultModel,
  PopOnScreenResultModel,
} from '/@/api/sd/call/model';
import { useUserStore } from './user';
import { Message } from '/@/utils/lib/core';
import { createVNode, h } from 'vue';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

export interface PhoneState {
  phone: Phone | null;
  status: 10 | 20 | 30 | 40; // 通话状态 未通话、呼叫中、通话中、来电中
  time: 0; // 本次通话时长-毫秒
  info:
    | (PopOnScreenResultModel['dialInfo'] & {
        callId: string; // 通话id
        taskId: string; // 任务id
        direction: 'outbound' | 'inbound'; // outbound 平台主动呼出 inbound 平台收到来电
        caller: string; // 主叫号码
        callerChannel: string; // 主叫通道
        callee: string; // 被叫号码
        calleeChannel: string; // 被叫通道
        belongingPlace?: {
          cardType: string; // 运营商
          province: string; // 省份
          city: string; // 城市
          number: string; // 手机号码
        }; // 对方的号码归属地; 内部通话时不存在
        ivr: string;
        identity: number;
        extension: string; // 对方的分机号
        dialLogList: PopOnScreenResultModel['dialLogList']; // 通话记录
      })
    | null; // 本次通话的信息
  agent: GetCallAgentResultModel | null; // 当前账号坐席信息
  extension: {
    displayName: string; // 坐席名称
    isRegistered: boolean; // 是否注册
    userName: string; // 分机号
    mute: boolean; // 静音
  } | null; // 分机号
  isConnection: boolean; // 软电话是否启动并连接成功
  modalDestroy: () => void; // 提示未安装弹窗的关闭方法
  isQueueLogin: boolean;
  option: {
    show: boolean; // 展开收起电话条
    notifyShow: boolean; // 展开时收起来电弹窗
  }; // 电话条配置
}

const { createMessage } = useMessage();

export const usePhoneStore = defineStore({
  id: 'app-phone',
  state: (): PhoneState => ({
    phone: null,
    status: 10,
    time: 0,
    info: null,
    agent: null,
    extension: null,
    isConnection: true,
    modalDestroy: () => {},
    isQueueLogin: false,
    option: { show: true, notifyShow: true },
  }),
  getters: {
    getPhone(): Phone {
      return this.phone as Phone;
    },
    getInfo(): PhoneState['info'] {
      return this.info;
    },
    // 通话时间格式化
    formatCallTime(): string {
      return dayjs.duration(this.time).format('HH:mm:ss');
    },
  },
  actions: {
    init() {
      this.phone = new Phone('ws://localhost:8080', 'kkl', 'admin', {
        sync: (message) => {
          // 同步最新状态
          if (message.event === 'sync') {
            this.extension = message.payload.extension;
            if (!this.isQueueLogin && this.extension?.isRegistered && this.agent?.queue) {
              queueLogin({
                queue: this.agent.queue,
                agent: this.agent.extension,
              });
              this.isQueueLogin = true;
              // 判断当前是否有通话，没有通话则清空通话信息
              if (message.payload.call.callee === '' && message.payload.call.caller === '') {
                this.clear();
              }
            }
          } else {
            console.log(message);
          }
        },
        invite: this.invite,
        login: this.getAgent,
        updateCallStatus: this.updateCallStatus,
        terminated: this.clear,
        hangupFailure: this.hangupFailure,
        registrySuccess: () => {
          if (this.extension) {
            this.extension.isRegistered = true;
          }
        },
        registryFailure: () => {
          if (this.extension) {
            this.extension.isRegistered = false;
          }
        },
        close: this.close,
        deviceFault: (message) => {
          createMessage.error(message.payload);
        },
      });
    },
    getAgent() {
      this.isConnection = true;
      this.modalDestroy();
      const userInfo = useUserStore().getUserInfo;
      getCallAgent(userInfo.id).then((res) => {
        this.agent = res;
        this.saveExtension();
      });
    },
    // 增加分机号
    async saveExtension() {
      if (!this.agent) {
        return;
      }
      await unregister(this.agent.extension);
      const response = await this.phone!.addExtension({
        userName: this.agent.extension,
        password: this.agent.password,
        displayName: this.agent.name,
        isRegistered: false,
        realm: this.agent.realm,
      });
      if (response.success) {
        console.log(response, '增加分机号成功');
      } else {
        createMessage.error(response.message);
      }
    },
    setStatus(val: PhoneState['status']) {
      this.status = val;
    },
    setInfo(info: PhoneState['info']) {
      this.info = info;
    },
    checkCall() {
      if (!this.isConnection) {
        // 提示未安装
        const href = 'http://192.168.12.135:9000/phone/qx-phone-setup_windows_release.exe';
        const { destroy } = Modal.error({
          width: 478,
          title: '提示',
          icon: createVNode(ExclamationCircleFilled),
          content: h('div', { style: { color: '#333' } }, [
            h('div', '一、未安装软电话软件'),
            h('div', [
              h('span', '请点击：'),
              h('a', { href, class: 'link', style: { textDecoration: 'underline' } }, href),
            ]),
            h('div', '下载并安装软件软件，安装并打开后，请刷新该页面。'),
            h('div', '二、已安装软电话软件'),
            h('div', '请保持打开软电话软件，并不要关闭，以确保呼叫顺利进行。'),
          ]),
          okText: '知道了',
        });
        this.modalDestroy = destroy;
        return false;
      }
      if (!this.extension) {
        createMessage.error('没有分机号');
        return false;
      }
      if (!this.extension.isRegistered) {
        createMessage.error('分机号未注册');
        return false;
      }
      if (this.status !== 10) {
        createMessage.error('当前通话状态不正确');
        return false;
      }
      return true;
    },
    // 呼叫
    call(params: DailCallParams) {
      if (this.checkCall()) {
        call({
          extension: this.extension!.userName,
          appId: 2,
          ...params,
        });
      }
    },
    // 收到来电/拨打电话
    async invite(message: Message) {
      if (this.status !== 10) return;
      // 内部通话的情况下矫正推送的呼叫类型
      if (message.payload.callee.length <= 5) {
        if (this.agent?.extension === message.payload.callee || message.payload.callee === '') {
          message.payload.direction = 'inbound';
        } else {
          message.payload.direction = 'outbound';
        }
      }
      const {
        callId,
        taskId,
        direction,
        caller,
        callee,
        callerChannel,
        calleeChannel,
        belongingPlace,
        extension,
        ivr,
        identity,
      } = message.payload;
      // 获取弹屏信息
      const res = await popOnScreen({
        taskId,
        direction,
        callerNumber: caller,
        calleeNumber: callee,
        calleeChannelId: callerChannel,
        callerChannelId: calleeChannel,
        ownership: belongingPlace?.province + belongingPlace?.city,
        extension,
        ivr,
        identity,
      });
      this.setInfo({
        ...res.dialInfo,
        direction,
        caller,
        callee,
        callerChannel,
        calleeChannel,
        belongingPlace,
        callId,
        taskId,
        dialLogList: res.dialLogList || [],
        extension,
        ivr,
        identity,
      });
      if (message.payload.direction === 'outbound') {
        this.setStatus(20);
      } else {
        this.setStatus(40);
      }
    },
    // 接听电话
    async answer() {
      if (this.info) {
        const response = await this.phone!.answer(this.info.callId);
        if (response.success) {
          this.enterCall();
        } else {
          createMessage.error(response.message);
        }
      } else {
        createMessage.error('不在通话中');
      }
    },
    // 进入通话中
    enterCall() {
      if ([10, 30].includes(this.status)) return;
      this.setStatus(30);
      setTimeout(this.timekeeping, 1000);
    },
    updateCallStatus(message: Message) {
      // 电话状态更新
      switch (message.payload.status) {
        case 'hangup':
          this.clear();
          break;
      }
    },
    hangupFailure(message: Message) {
      // 通话不存在
      if (message.payload.message === 'callID is not exist') {
        this.clear();
      }
    },
    // 拒接(被叫)
    async reject() {
      if (this.info) {
        const response = await this.phone!.reject(this.info.callId);
        if (response.success) {
          this.clear();
        } else {
          createMessage.error(response.message);
        }
      } else {
        createMessage.error('不在通话中');
      }
    },
    // 挂断电话(主动挂断)
    async terminated() {
      if (this.info) {
        this.phone!.hangup(this.info.callId);
      } else {
        createMessage.error('不在通话中');
      }
    },
    // 计时
    timekeeping() {
      if (this.status === 30) {
        this.time += 1000;
        setTimeout(this.timekeeping, 1000);
      } else {
        this.time = 0;
      }
    },
    // 通话结束，清空相关数据
    clear() {
      this.setInfo(null);
      this.setStatus(10);
    },
    close() {
      this.isConnection = false;
      this.clear();
      this.extension = null;
    },
    setOption(option: PhoneState['option']) {
      Object.assign(this.option, option);
    },
  },
});

// Need to be used outside the setup
export function usePhoneStoreWithOut() {
  return usePhoneStore(store);
}
