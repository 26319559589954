/*
 * @Author: Mr.Wang
 * @Date: 2022-03-16 15:37:01
 * @LastEditTime: 2022-06-06 16:55:02
 * @LastEditors: gradiaun
 * @Description:
 * @FilePath: \cilantro-admin-web\src\utils\crypto.ts
 */

import { secretKey } from './config/base';
import { AES, mode, pad, enc } from 'crypto-js';
import { isString } from 'lodash';

/**
 * 解密内容
 * @param content
 * @param key
 * @returns
 */
export const Decrypt = (content: string, key?: string): string | object => {
  const _key = enc.Utf8.parse(key ?? secretKey);
  const _content = AES.decrypt(content, _key, {
    iv: _key,
    mode: mode.CBC,
    padding: pad.ZeroPadding,
  }).toString(enc.Utf8);
  // console.log(content)
  // let str = '';
  // Object.keys(_content).map((k) => {
  //   // @ts-ignore
  //   str += _content[k];
  // });
  // console.log(str)
  try {
    return JSON.parse(_content);
  } catch (error) {
    // console.log(error, 'error')
    return _content;
  }
};

/**
 * 加密内容
 * @param content
 * @param key
 */
export const Encrypt = (content: string | object, key?: string): string => {
  const _key = enc.Utf8.parse(key ?? secretKey);
  const _content = isString(content) ? content : JSON.stringify(content);
  return AES.encrypt(_content, _key, {
    iv: _key,
    mode: mode.CBC,
    padding: pad.ZeroPadding,
  }).toString();
};

/**
 *
 * @param content
 * @returns
 */
export const Encode = (content: string) => {
  if (typeof content !== 'string' && content != '') {
    return;
  }
  const offset = (content.charCodeAt(0) - content.charCodeAt(1)) % 13;
  const strings = content.split('').map((item) => {
    return String.fromCharCode(offset + item.charCodeAt(0));
  });
  return String.fromCharCode(offset + 97) + strings.join('');
};

export const Decode = (content: string) => {
  if (typeof content !== 'string') {
    return;
  }
  const offset = content.charCodeAt(0) - 97;
  const strings = content.split('').map((item) => {
    return String.fromCharCode(item.charCodeAt(0) - offset);
  });
  strings.shift();
  return strings.join('');
};
