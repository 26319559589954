import { RouterMap } from '../../types';

export const routeMap: RouterMap = new Map();

routeMap.set('/workbench', {
  name: 'Workbench',
  meta: {
    hideChildrenInMenu: true,
    affix: true,
  },
});
