import {
  AddOrderItemParams,
  AddOrderItemResultModel,
  DelOrderItemParams,
  DelOrderItemResultModel,
  GetOrderResultModel,
  RepeatOrderResultModel,
} from '../order/model';
import {
  B2bOrderPageParams,
  B2bOrderPageResultModel,
  B2bOrderCancelParams,
  B2bOrderCancelResultModel,
  B2bManualTransferOrderParams,
  CheckOrderB2BResultModel,
  OrderConfirmB2BResultModel,
  B2bOrderRefreshParams,
  B2bOrderIgnoreParams,
  B2bOrderIgnoreResultModel,
  B2bOrderBatchTransferParams,
  B2bOrderBatchTransferResultModel,
  AddB2BSystemRoutingParams,
  AddCtB2BProductListParams,
  AddCtB2BServiceTypeListParams,
  GetB2BCustomerListResultModel,
  GetB2BOrderProductInfoParams,
  GetB2BOrderProductInfoResultModel,
  GetB2BOrderServiceTypeInfoParams,
  GetB2BOrderServiceTypeInfoResultModel,
  GetB2BShopListParams,
  GetB2BShopListResultModel,
  B2bSaveShopParams,
  B2bSaveDataSourceParams,
  GetDataSourceConfigListResultModel,
} from './model';
import { mdHttp, sdHttp } from '/@/utils/http/axios';

export enum Api {
  B2bOrderPage = '/b2b/order/getPage',
  B2bOrderCancel = '/b2b/order/cancel',
  B2bManualTransferOrder = '/b2b/order/manualTransferOrder',
  AddOrderItemB2B = '/b2b/order/addOrderItem',
  DelOrderItemB2B = '/b2b/order/delOrderItem',
  UpdateOrderItemB2B = '/b2b/order/updateOrderItem',
  CheckOrderB2B = '/b2b/order/checkOrder',
  SaveOrderB2B = '/b2b/order/saveOrder',
  RepeatOrderB2B = '/b2b/order/repeatOrder',
  OrderConfirmB2B = '/b2b/order/confirm',
  B2bOrderRefresh = '/b2b/order/refresh',
  B2bOrderIgnore = '/b2b/order/ignore',
  B2bOrderBatchTransfer = '/b2b/order/batchTransfer',
  GetB2BOrderServiceTypeInfo = '/b2b/config/getB2BOrderServiceTypeInfo',
  AddCtB2BServiceTypeList = '/b2b/config/addCtB2BServiceTypeList',
  GetB2BOrderProductInfo = '/b2b/config/getB2BOrderProductInfo',
  AddCtB2BProductList = '/b2b/config/addCtB2BProductList',
  GetB2BCustomerList = '/b2b/config/getCustomerList',
  GetB2BShopList = '/b2b/config/getShopList',
  AddB2BSystemRouting = '/b2b/config/addB2BSystemRouting',
  B2bSaveShop = '/b2b/save/shop',
  B2bSaveDataSource = '/ct/b2b/customer/shop/dataSource/save',
  GetDataSourceConfigList = '/b2b/config/getDataSourceConfigList',
}

// 1.分页查询,
export function b2bOrderPage(params: B2bOrderPageParams) {
  return sdHttp.get<B2bOrderPageResultModel>({
    url: Api.B2bOrderPage,
    params,
  });
}

// 2.取消工单,
export function b2bOrderCancel(data: B2bOrderCancelParams) {
  return sdHttp.post<B2bOrderCancelResultModel[]>({
    url: Api.B2bOrderCancel,
    data,
  });
}

// 8.手工转单,
export function b2bManualTransferOrder(params: B2bManualTransferOrderParams) {
  return sdHttp.get<GetOrderResultModel>({
    url: Api.B2bManualTransferOrder,
    params,
  });
}

// 9.添加下单产品,
export function addOrderItemB2B(data: AddOrderItemParams) {
  return sdHttp.post<AddOrderItemResultModel>({
    url: Api.AddOrderItemB2B,
    data,
  });
}

// 10.删除产品,
export function delOrderItemB2B(data: DelOrderItemParams) {
  return sdHttp.delete<DelOrderItemResultModel>({
    url: Api.DelOrderItemB2B,
    params: data,
  });
}

// 11.修改产品,
export function updateOrderItemB2B(data: AddOrderItemParams) {
  return sdHttp.put<AddOrderItemResultModel>({
    url: Api.UpdateOrderItemB2B,
    data,
  });
}

// 12.校验工单是否重单,
export function checkOrderB2B(data: GetOrderResultModel) {
  return sdHttp.post<CheckOrderB2BResultModel>({
    url: Api.CheckOrderB2B,
    data,
  });
}

// 13.保存工单,
export function saveOrderB2B(data: GetOrderResultModel) {
  return sdHttp.post<boolean>({
    url: Api.SaveOrderB2B,
    data,
  });
}

// 14.重单,
export function repeatOrderB2B(tempOrderKey: string) {
  return sdHttp.post<RepeatOrderResultModel>({
    url: Api.RepeatOrderB2B,
    data: { tempOrderKey },
  });
}

// 15.确认费用信息,
export function orderConfirmB2B(tempOrderKey: string) {
  return sdHttp.post<OrderConfirmB2BResultModel>({
    url: Api.OrderConfirmB2B,
    data: { tempOrderKey },
  });
}

// 7.刷新,
export function b2bOrderRefresh(params: B2bOrderRefreshParams) {
  return sdHttp.get<boolean>({
    url: Api.B2bOrderRefresh,
    params,
  });
}

// 3.忽略隐藏,
export function b2bOrderIgnore(data: B2bOrderIgnoreParams) {
  return sdHttp.post<B2bOrderIgnoreResultModel[]>({
    url: Api.B2bOrderIgnore,
    data,
  });
}

// 4.批量转单,
export function b2bOrderBatchTransfer(data: B2bOrderBatchTransferParams) {
  return sdHttp.post<B2bOrderBatchTransferResultModel[]>({
    url: Api.B2bOrderBatchTransfer,
    data,
  });
}

// 1.查询一键配置-服务类型关联配置界面信息,
export function getB2BOrderServiceTypeInfo(params: GetB2BOrderServiceTypeInfoParams) {
  return sdHttp.get<GetB2BOrderServiceTypeInfoResultModel>({
    url: Api.GetB2BOrderServiceTypeInfo,
    params,
  });
}

// 3.保存B2B服务类型关联配置,
export function addCtB2BServiceTypeList(data: AddCtB2BServiceTypeListParams) {
  return mdHttp.post<boolean>({
    url: Api.AddCtB2BServiceTypeList,
    data,
  });
}

// 2.查询一键配置-客户产品关联配置界面信息,
export function getB2BOrderProductInfo(params: GetB2BOrderProductInfoParams) {
  return sdHttp.get<GetB2BOrderProductInfoResultModel>({
    url: Api.GetB2BOrderProductInfo,
    params,
  });
}

// 4.保存B2B客户产品关联配置,
export function addCtB2BProductList(data: AddCtB2BProductListParams) {
  return mdHttp.post<boolean>({
    url: Api.AddCtB2BProductList,
    data,
  });
}

// 1.查询客户列表(当前用户可见),
export function getB2BCustomerList() {
  return mdHttp.get<GetB2BCustomerListResultModel>({
    url: Api.GetB2BCustomerList,
  });
}

// 2.查询客户下的店铺列表(当前用户可见),
export function getB2BShopList(params: GetB2BShopListParams) {
  return mdHttp.get<GetB2BShopListResultModel>({
    url: Api.GetB2BShopList,
    params,
  });
}

// 5.保存B2B系统路由配置, 系统转发
export function addB2BSystemRouting(data: AddB2BSystemRoutingParams) {
  return mdHttp.post<boolean>({
    url: Api.AddB2BSystemRouting,
    data,
  });
}

// 3.新增店铺,
export function b2bSaveShop(data: B2bSaveShopParams) {
  return mdHttp.post<boolean>({
    url: Api.B2bSaveShop,
    data,
  });
}

// 4.新增店铺配置,
export function b2bSaveDataSource(data: B2bSaveDataSourceParams) {
  return mdHttp.post<boolean>({
    url: Api.B2bSaveDataSource,
    data,
  });
}

// 4.查询数据源配置,
export function getDataSourceConfigList() {
  return sdHttp.get<GetDataSourceConfigListResultModel>({
    url: Api.GetDataSourceConfigList,
  });
}
