/*
 * @Author: jeardwang jeardwang@sina.com
 * @Date: 2023-09-01 15:25:26
 * @LastEditors: jeardwang jeardwang@sina.com
 * @LastEditTime: 2023-10-20 14:08:31
 * @FilePath: /qx-phone-lib/lib/phone.ts
 * @Description: 话机 获取话机信息
 */
import {
  Call,
  Contact,
  EventName,
  ExtensionInfo,
  Message,
  PhoneInfo,
  PhoneState,
  Result,
} from './core';
import { Extension } from './extension';
import { createMessage } from './message';
import { WebSocketClient } from './websocket';

export class Phone {
  private url: string;
  private appId: string;
  private userId: string;
  private websocket: WebSocketClient;
  private extension: Extension | undefined = undefined;
  private calls: { [key: string]: Call } = {};
  private method: { [key in EventName]?: (message: Message) => void } = {
    connect: () => {
      const loginData: Message = {
        ...createMessage(),
        event: 'register',
        payload: {
          appId: this.appId,
          userId: this.userId,
        },
      };
      this.websocket.send(loginData);
    },
    invite: (message) => {
      const call = message.payload as Call;
      this.calls[call.callId] = call;
      console.log('invite', call);
    },
  };
  private onMessage = (message: Message) => {
    if (message.event === 'invite') {
      const call = message.payload as Call;
      this.calls[call.callId] = call;
    }
    const method = this.method[message.event as EventName];
    if (method) {
      method(message);
    }
  };

  // constructor 构造函数
  constructor(
    url: string,
    appId: string,
    userId: string,
    callbacks?: { [key in EventName]?: (message: Message) => void },
  ) {
    this.url = url;
    this.appId = appId;
    this.userId = userId;
    this.method = {
      ...this.method,
      ...callbacks,
    };
    this.websocket = new WebSocketClient(this.url, this.onMessage);
    this.connect();
  }

  // connect 连接
  public connect() {
    this.websocket.connect().then(() => {
      this.websocket.receive(this.onMessage);
    });
  }

  // disconnect 断开连接
  public disconnect(): void {
    this.websocket.close();
  }

  // start extension
  // addExtension 增加分机
  public async addExtension(extension: ExtensionInfo): Promise<Result<ExtensionInfo>> {
    const ext = new Extension(extension, this.websocket);
    this.extension = ext;
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'addExtension',
      payload: extension,
    });
    const result: Result<ExtensionInfo> = {
      success: response.success,
      message: response.message,
      data: extension,
    };
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
      result.data = response.data!.payload.extension;
    }
    return result;
  }

  // createExtension 创建分机
  public async createExtension(extension: ExtensionInfo) {
    const ext = new Extension(extension, this.websocket);
    this.extension = ext;
  }
  // removeExtension 删除分机
  public async removeExtension(extension: string): Promise<Result<Extension | null>> {
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'removeExtension',
      payload: extension,
    });
    const result: Result<Extension | null> = {
      success: response.success,
      message: response.message,
      data: null,
    };
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
      if (result.success) {
        this.extension = undefined;
      }
    }
    return result;
  }
  // getExtensionByExtension 根据分机号获取分机
  public getExtensionByExtension(): Extension | undefined {
    return this.extension;
  }
  // listExtensions 获取分机列表
  public async listExtensions(param?: string): Promise<Result<ExtensionInfo[]>> {
    const result: Result<ExtensionInfo[]> = {
      success: false,
      message: '',
      data: [],
    };
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'listExtension',
      payload: param,
    });
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
      result.data = (response.data!.payload.records as ExtensionInfo[]) ?? [];
    }
    return result;
  }
  // fetchExtensionByCallId 通过通话ID获取分机
  public fetchExtensionByCallId(): Extension | undefined {
    return this.extension;
  }

  // registryExtension 注册分机
  public async registryExtension(): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const ext = this.extension;
    if (!ext) {
      result.message = '分机不存在';
      return result;
    }
    const response = await ext.registry();
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
    }
    return result;
  }
  // unRegistryExtension 注销分机
  public async unRegistryExtension(): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const ext = this.extension;
    if (!ext) {
      result.message = '分机不存在';
      return result;
    }
    const response = await ext.unregistry();
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
    }
    return result;
  }
  // dial 拨号
  public async dial(_extension: string, number: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const ext = this.getExtensionByExtension();
    if (!!ext) {
      const response = await ext.dial(number);
      if (response.success) {
        result.success = response.data!.payload.success;
        result.message = response.data!.payload.message;
      }
    } else {
      result.message = '分机不存在';
    }
    return result;
  }

  // hangup 挂断
  public async hangup(callId: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.hangup(callId);
      if (response.success) {
        result.success = response.data!.payload.success;
        result.message = response.data!.payload.message;
      }
    } else {
      result.message = '通话不存在';
    }
    return result;
  }
  // answer 接听
  public async answer(callId: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.answer(callId);
      if (response.success) {
        result.success = response.data!.payload.success;
        result.message = response.data!.payload.message;
      }
    } else {
      result.message = '通话不存在';
    }
    return result;
  }
  // reject 拒接
  public async reject(callId: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.answer(callId);
      if (response.success) {
        result.success = response.data!.payload.success;
        result.message = response.data!.payload.message;
      }
    } else {
      result.message = '通话不存在';
    }
    return result;
  }
  // transfer 呼叫转移
  public async transfer(callId: string, number: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.transfer(callId, number);
      if (response.success) {
        result.success = response.data!.payload.success;
        result.message = response.data!.payload.message;
      }
    } else {
      result.message = '通话不存在';
    }
    return result;
  }

  // dtmf 发送dtmf
  public async dtmf(callId: string, dtmf: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.dtmf(callId, dtmf);
      if (response.success) {
        result.success = response.data!.payload.success;
        result.message = response.data!.payload.message;
      }
    } else {
      result.message = '通话不存在';
    }
    return result;
  }

  // mute 静音
  public async mute(callId: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.mute(callId);
      result.success = response.success;
    } else {
      result.message = '通话不存在';
    }
    return result;
  }

  // unmute 取消静音
  public async unmute(callId: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.unmute(callId);
      result.success = response.success;
    } else {
      result.message = '通话不存在';
    }
    return result;
  }

  // hold 保持
  public async hold(callId: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.hold(callId);
      if (response.success) {
        result.success = response.data!.payload.success;
        result.message = response.data!.payload.message;
        const call = this.calls[callId];
        if (call) {
          call.status = 'hold';
        }
      }
    } else {
      result.message = '通话不存在';
    }
    return result;
  }

  // unhold 取消保持
  public async unhold(callId: string): Promise<Result<null>> {
    const result: Result<null> = {
      success: false,
      message: '',
      data: null,
    };
    const extension = this.fetchExtensionByCallId();
    if (!!extension) {
      const response = await extension.unhold(callId);
      if (response.success) {
        result.success = response.data!.payload.success;
        result.message = response.data!.payload.message;
        const call = this.calls[callId];
        if (call) {
          call.status = 'talking';
        }
      }
    } else {
      result.message = '通话不存在';
    }
    return result;
  }

  // end extension

  // start contact
  // addContact 增加联系人
  public async addContact(contact: Contact): Promise<Result<Contact | null>> {
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'addContact',
      payload: contact,
    });
    const result: Result<Contact | null> = {
      success: response.success,
      message: response.message,
      data: null,
    };
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
      result.data = response.data!.payload.contact as Contact;
    }
    return result;
  }

  // removeContact 删除联系人
  public async removeContact(id: number): Promise<Result<null>> {
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'removeContact',
      payload: id,
    });
    const result: Result<null> = {
      success: response.success,
      message: response.message,
      data: null,
    };
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
    }
    return result;
  }

  // updateContact 更新联系人
  public async updateContact(contact: Contact): Promise<Result<Contact | null>> {
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'updateContact',
      payload: contact,
    });
    const result: Result<Contact | null> = {
      success: response.success,
      message: response.message,
      data: null,
    };
    if (response.success) {
      result.message = response.data!.payload.message;
      result.data = response.data!.payload as Contact;
    }
    return result;
  }

  // listContacts 列出联系人
  public async listContacts(): Promise<Result<Contact[]>> {
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'listContacts',
    });
    const result: Result<Contact[]> = {
      success: response.success,
      message: response.message,
      data: [],
    };
    if (response.success) {
      result.message = response.data!.payload.message;
      result.data = response.data!.payload.contacts as Contact[];
    }
    return result;
  }

  // QueryContact 查询联系人
  public async queryContact(numberOrName: string): Promise<Result<Contact[]>> {
    if (!numberOrName) {
      return this.listContacts();
    }
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'queryContact',
      payload: numberOrName,
    });
    const result: Result<Contact[]> = {
      success: response.success,
      message: response.message,
      data: [],
    };
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
      result.data = response.data!.payload.contacts as Contact[];
    }
    return result;
  }

  // end contact

  public async listRecords(): Promise<Result<Call[]>> {
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'listRecords',
    });
    const result: Result<Call[]> = {
      success: response.success,
      message: response.message,
      data: [],
    };
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
      result.data = response.data!.payload.records as Call[];
    }
    return result;
  }

  // queryRecords 查询通话记录
  public async queryRecords(param: string): Promise<void | Result<Call[]>> {
    if (!param) {
      return this.listRecords();
    }
    const response = await this.websocket.sendAsync({
      ...createMessage(),
      event: 'queryRecords',
      payload: param,
    });
    const result: Result<Call[]> = {
      success: response.success,
      message: response.message,
      data: [],
    };
    if (response.success) {
      result.success = response.data!.payload.success;
      result.message = response.data!.payload.message;
      result.data = response.data!.payload.records as Call[];
    }
    return result;
  }

  // info 话机信息
  public info(): Result<PhoneInfo> | void {
    console.log('info phone');
  }

  // restart 重启动话机
  public restart(): Result<null> | void {
    console.log('restart phone');
  }

  // shutdown 关闭话机
  public shutdown(): Result<null> | void {
    console.log('shutdown phone');
  }

  // state 话机状态
  public state(): Result<PhoneState> | void {}

  // onExtensionChange 监听分机状态变化
  public onExtensionChange(): void {}
}
