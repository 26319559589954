/*
 * @Author: Mr.Wang
 * @Date: 2022-03-16 17:03:21
 * @LastEditTime: 2022-07-02 11:21:45
 * @LastEditors: kongxiangbo(2036746012@qq.com)
 * @Description:
 * @FilePath: \cilantro-admin-web\src\config\base.ts
 */

// AES 加密Key
export const secretKey = import.meta.env.VITE_SECRET_KEY;

export const webSocketURL = '';

// 事业部
export const businessOptions = [
  {
    icon: 'kitchen',
    title: '厨卫',
    value: 10,
  },
  {
    icon: 'VRV',
    title: '空调',
    value: 20,
  },
  {
    icon: 'charging',
    title: '充电桩',
    value: 30,
  },
];
